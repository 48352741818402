.wrapper {
  background: url("../assets/spta/welcome-background.jpg");
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: bottom;
}

.container {
  width: 80%;
  max-width: 450px;
  background: #fcefe6;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  text-align: center;
  border-radius: 15px;
  border: 5px solid #000;
  box-shadow: 0 4px #000;
  align-items: center;
}
.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.form-input {
  width: 100%;
  height: 40px;
  font-size: 15px;
  border-radius: 10px;
  padding: 5px;
  outline: none;
  border: 2px solid #000;
  background: #ffede9;
  color: #000;
}

.form-item {
  width: 100%;
  margin-bottom: 10px;
}
.logo {
  width: 80%;
  max-width: 350px;
  margin-bottom: 30px;
}

.button {
  color: #000;
  background-color: #fff;
  border: 5px solid #000;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding-left: 35px;
  padding-right: 35px;
  font-family: Tanker-Regular, sans-serif;
  font-size: 25px;
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  box-shadow: 0 4px #000;
}

.button:hover {
  transform: rotate(-2deg);
  box-shadow: 0 6px #000;
}

h1 {
  text-transform: uppercase;
  font-family: Tanker-Regular;
  font-size: 35px;
}
p {
  margin-bottom: 10px;
  font-family: Satoshi-Regular;
}
