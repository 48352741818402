.sky {
  background:  #362d89 ;
  height: 100%;
  position: relative;
  animation: mymove 60s;
  animation-iteration-count: 1;
}

@keyframes mymove {
  0% {background-color: #ffba95;}
  40% {background-color: #ffba95;}
  90% {background-color: #362d89; }
  100% {background-color: #362d89; }
}


@keyframes fete {
  0% {background-color: #ffba95;}
  25% {background-color: #e328b1;}
  50% {background-color: #9e93ff; }
  75% {background-color: #28e338;}
  100% {background-color: #ffba95;}
}

.sun {
  width: 100px;
  height: 100px;
  background-color: rgb(245, 218, 131);
  border-radius: 50%;
  position: absolute;
  top: 50px;
  right: 100px;
}
