@font-face {
  font-family: "Tanker-Regular";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/Tanker-Regular.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/Satoshi-Regular.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

body {
  user-select: none;
  font-family: "Verdana", cursive;
}

img {
  pointer-events: none;
}
